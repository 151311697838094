.Message {
    position: absolute;
    top: 2.5em;
    left: 2.5em;
    bottom: 2.5em;
    right: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Message .Text {
    font-weight: bold;
    white-space: pre;
    text-align: center;
}

.Message .Measurement {
    position: absolute;
    left: 0;
    top: 0;
    visibility: hidden;
    font-weight: bold;
    white-space: pre;
}