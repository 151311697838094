.TwitchViewerCount {
    text-align: right;
    font-size: 250%;
}

.TwitchViewerCount .Icon {
    display: inline;
    position: relative;
    top: 0.2em;
    padding-right: 0.2em;
}

.TwitchViewerCount .Count {
    display: inline;
}

.TwitchViewerCount .Max {
    display: inline;
}

.TwitchViewerCount .Max:before {
    content: '/'
}

.TwitchViewerCount .Max:after {
    content: ''
}

.TwitchViewerCount .NoMax {
    display: none;
}
