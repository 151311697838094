.Viewer {
    font-family: "Droid Serif";
    color: whitesmoke;
    text-shadow:
            -1px -1px 0 grey,
            1px -1px 0 grey,
            -1px 1px 0 grey,
            1px 1px 0 grey;
    display: grid;
    grid-template-rows: 1fr max-content;
    backdrop-filter: blur(80px);
}

.Background {
    background-image: linear-gradient(30deg, red, orange, yellow, green, cyan, blue, violet, red);
    width: 100%;
    height: 100%;
    position: absolute;
    display: grid;
    z-index: 1;
}

.Viewer .Center {
    grid-row: 1;
    padding: 2.5em;
    display: flex;
}

.Viewer .Left {
}

.Viewer .Expand {
    flex-grow: 1;
}

.Viewer .Right {
}

.Viewer .Bottom {
    grid-row: 2;
}