.Crate {
    padding: 1em;
}

.Table {
    height: 300px;
}

.LoadButtonCell {
}

.ArtistCell {
}

.TitleCell {
}

.CoverCell {
    padding: 0 !important;
    padding-top: 8px !important;
}

.CoverCell img {
    max-width: 4em;
    max-height: 4em;
}

.ResetButton.ResetButton { /* increase the specificity to override MuiButton’s styling. */
    background-color: red;
}

.ResetButton.ResetButton:hover {
    background-color: hsl(0, 100%, 46%);
}
