.Version {
    flex-grow: 1;
    text-align: right;
}

.VersionInformation {
    padding-left: 1ex;
}

.Warning {
    background-color: red;
    color: yellow;
    padding: 0.5ex 1ex;
    border-radius: 1ex;
    margin: -0.5ex -1ex;
}
