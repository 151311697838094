.Group {
}

.Group .GroupTitle {
}

.Group .GroupTitle .expandOpen {
    transform: rotate(180deg);
}

.Group .GroupContent {
    padding: 0 !important;
}
