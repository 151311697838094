.NextShow {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-top: 0.5em;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    overflow: hidden;
    height: 1.5em;
    font-size: 150%;
}

.NextShow .Text {
    position: relative;
    animation-name: slide-to-the-left;
    animation-duration: 60s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    text-align: center;
    width: 100%;
}

@keyframes slide-to-the-left {
    from {
        opacity: 0;
        margin-left: 100%;
        animation-timing-function: ease;
    }

    1% {
        opacity: 0;
    }

    4% {
        opacity: 1;
        margin-left: 0;
        animation-timing-function: ease;
    }

    12% {
        opacity: 1;
        margin-left: 0;
        animation-timing-function: ease;
    }

    16% {
        opacity: 0;
    }

    20% {
        opacity: 0;
        margin-left: -100%;
    }

    to {
        opacity: 0;
        margin-left: -100%;
    }
}
