.TrackInfo {
    display: inline-grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: 1fr min-content auto auto;
    grid-template-areas: "cover number title" "cover number artist" "cover last-track last-track";
    font-size: 200%;
    bottom: 0;
    padding: 1em;
    margin: 1.25em;
    background: rgba(0, 0, 0, 0.5);
}

.TrackInfo .Cover {
    grid-area: cover;
    padding-right: 1em;
    max-width: 5em;
    max-height: 5em;
}

.TrackInfo .Cover img {
    max-width: 100%;
    max-height: 100%;
}

.TrackInfo .Number:before {
    content: '#';
}

.TrackInfo .Number {
    grid-area: number;
    align-self: end;
    font-size: 200%;
    font-weight: bold;
    padding-right: 0.4em;
    margin-bottom: 0.1ex;
}

.TrackInfo .Artist {
    grid-area: artist;
    align-self: end;
    font-weight: bold;
}

.TrackInfo .Title {
    grid-area: title;
    align-self: end;
    margin-bottom: 0.5ex;
    font-size: 120%;
}

.TrackInfo .LastTrack {
    grid-area: last-track;
    margin-top: 0.5ex;
    font-size: 80%;
}

.TrackInfo .LastArtist, .TrackInfo .LastTitle {
    display: inline;
}

.TrackInfo .LastTrack:before {
    content: 'Previous Track: '
}

.TrackInfo .LastTrack .LastTitle:before {
    content: ' – '
}
